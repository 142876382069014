import React from 'react'
import { Stack, Main } from '@layout'
import { Box, Link } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default () => (
  <>
    <Seo title='Customer Service' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle header="Customer Service" />
        </Section>
        <Divider />
        <Section>
          <PageTitle running='Thank you so much for visiting at Zenvava.com online store!
          We ship worldwide direct from the brand warehouses in China or Japan.
          We contact with our partner makers very closely so that your order items will be arrived to you smoothly and correctly.
          Please kindly check this page at first because we hope you will be a happy customer.'
          />
        </Section> 
        <Divider />
        <Section>
          <PageTitle subheader='Shop Information'/>
            <Box>
              <ul>
              <li><Link href='/customer-service#ordering' target='_self'>Ordering</Link></li>
              <li><Link href='/customer-service#payment' target='_self'>Payment</Link></li>
              <li><Link href='/customer-service#shipping' target='_self'>Shipping & Delivery</Link></li>
              <li><Link href='/customer-service#returns' target='_self'>Returns & Refunds</Link></li>
              <li><Link href='/customer-service#contact' target='_self'>Contact Us</Link></li>
              </ul>
            </Box>
        </Section>
        <Divider />
        <Section>
          <a name="ordering"><PageTitle subheader='Ordering'/></a>
          <PageTitle subheader='Create an account'
          running='
          By creating an account with our store, you will be able to move through the checkout process faster, view your orders in your account and more.
          You can update your account information any time.'
          />
          <PageTitle subheader='Place an Order'
          running='Please add your order items in your shopping cart and proceed to checkout with your account.
          All the orders must be placed through our website, mobile site.
          The base charging currency is USD(United States Dollar) on our store.
          We will send an order confirmation email to your email address once the order is confirmed.'
          />
        </Section>
        <Divider />
        <Section>
          <a name="payment"><PageTitle subheader='Payment'/></a>
          <PageTitle running='
          We accept PayPal payment.
          You can also pay with your credit card through PayPal checkout secured page although you don’t have your PayPal account.
          The billing currency is in USD. Should you pay in your local currency, the bill mount is calculated accordingly through PayPal exchange rates.
          We ship to your PayPal address.
          Please make sure your shipping address is current and correct.'
          />
        </Section>
        <Divider />
        <Section>
          <a name="shipping"><PageTitle subheader='Shipping & Delivery'/></a>
          <PageTitle subheader='International Shipping'
          running='
          All the items will be shipped from the relevant brand warehouses via international express parcel with a tracking number.
          Basically, each brand items will be shipped from each brand warehouses. So, if you purchase multiple brands,
          we will first let these multiple packages put into one package together, then ship it together from the nearest port in China or Japan.
          In this case, please be aware there will be a few more extra handling days needed before the final shipping.'
          />
          <PageTitle subheader='International Shipping'
          running='
          Currently we ship to over 50 countries. International Shipping Rates to Some Major Countries are below.
          Please kindly contact us if you can not find your destination country name in the list.
          We will ask the logistic partners whether the items are able to be shipped or not.'
          />
          <PageTitle subheader='Tracking'
          running='
          We send the tracking number information to your email address after shipping.
          You can trace your package on the tracking website.'
          />
          <PageTitle subheader='Handling Time & Delivery Time'
          running='
          Your item will be shipped within 1-3 business days after placing your order except some custom ordered items.
          Please be aware with international shipping can take up to 4-14 days or more to arrive.
          Delivery time depends on the destination, holidays, customs, weather and other various factors.
          There may be delay for some reasons, but we can not control once they have been shipped.
          Thank you so much for your understanding.'
          />
          <PageTitle subheader='Unexpectedly No Stock'
          running='
          We always check the item stocks up-to-date as correct as possible.
          However almost all the items are on sale at another platforms or real stores.
          We will inform you as soon as possible if your order items are not available.
          You can choose to wait for the re-arrival or to cancel the order.
          If the brand maker never re-produces the item, your order will be no way but to be cancelled.'
          />
          <PageTitle subheader='Made to Order Production'
          running='
          Some items are made-to-order productions.
          Every pages of the made-to-order productions is displaying how long does it take to make your original items.'
          />
          <PageTitle subheader='Custom Duties'
          running='
          Please kindly note you are responsible for any and all the customs duties and charges that may be incurred at your country’s airport.
          We recommend checking out your country’s policies regarding the import for personal use.'
          />
          <PageTitle subheader='Unclaimed'
          running='
          If your order items come back unclaimed, we would like to reship as a general rule.
          We will ask you the correct/new address.
          In this case, there will be an extra shipping charge if the unclaimed reason is not our mistake.
          Also your order may be canceled if we are not able to contact with you for 7 days after the unclaimed.
          Please kindly note we will refund you deducting the first shipping fee in this case.
          So please double-check your PayPal address is current and correct before placing your order.
          We ship to your PayPal address basically.'
          />
        </Section>
        <Divider />
        <Section>
          <a name="returns"><PageTitle subheader='Returns & Refunds'/></a>
          <PageTitle subheader='Returns'
          running='
          If you are not satisfied with our products, you are able to return the items to us if the original conditions and tags are kept.
          Please contact us within 7 days of receipt. We will let you know the return address(Japan).
          After receiving the items back, we will refund you as soon as possible via PayPal.
          We would appreciate you could pay for the return shipping fee if the return reason is not at our store side mistake.'
          />
          <PageTitle subheader='Faulty / Incorrect items'
          running='
          If the item is faulty or incorrect, you can require a refund. At first, please message us and let us know the details.
          We will contact the brand maker and negotiate with them.
          We will do our best for you to be a happy customer in every possible way.'
          />
          <PageTitle subheader='Exchanges'
          running='
          We are so sorry, but we are not able to accept international exchanges for different sizes or different colors and etc.
          We would appreciate if you could contact us at first before placing your order if you have any questions or concerns about the sizes, colors and etc.
          Thank you so much for your understanding.'
          />
          <PageTitle subheader='Delivery Accidents'
          running='
          We are so sorry, we are not responsible for the damages and losses, or the failure delivery.
          We cannot control over these accidents during the delivery.
          However we will do our best for you to be a happy customer in every possible way.
          You can request the brand maker to reship your items in case there is some defects in the packaging.
          Please contact us at first, and let us know the situation. We can work out a solution.'
          />
        </Section>
        <Divider />
        <Section>
          <a name="contact"><PageTitle subheader='Contact us'/></a>
          <PageTitle running='
          If you have any questions or concerns, please feel free to message us.
          We’d appreciate if you could wait our returns because our business hour is on GMT+9 Tokyo local time.
          We love to know what do you think of our items & services! '
          />
          <Box>
          <Link href="/contact" target='_self'>Contact us</Link>
          </Box>
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Thank you & Happy shopping!'/>
        </Section>
      </Main>
    </Stack>
  </>
)
